$(function() {

// ページ内リンクスムース
  // $('a[href^="#"]').click(function() {
  //   var speed = 400;
  //   var href = $(this).attr("href");
  //   var target = $(href == "#" || href == "" ? 'html' : href);
  //   var position = target.offset().top;
  //   $('body,html').animate({
  //     scrollTop: position
  //   }, speed, 'easeInOutQuad');
  //   return false;
  // });

// drawer
  $('.drawer').drawer();

  // 外部サイトは新規タブ
  $('a[href^="http"]').not('[href^="https"]').not('[href^="https://kb-mirai.net/"]').click(function() {
    window.open(this.href, '_blank');
    return false;
  }).addClass('external');

  // 表示ページナビにclass付加
  var activeUrl = location.pathname.split("/")[1];
    navList = $(".gNav__item").find("a");


  navList.each(function() {
    if ($(this).attr("href").split("/")[1] == activeUrl) {
      $(this).parent("li").addClass("current");
    };
  });

  // PC tel Off
  var ua = navigator.userAgent;
  if (ua.indexOf('iPhone') < 0 && ua.indexOf('Android') < 0) {
    $('a[href^="tel"]').each(function() {
      $(this).contents().unwrap();
    });
  }

  // Image hover
  // $('.img_hover img').hover(function() {
  //     $(this).attr('src', $(this).attr('src').replace('_off', '_on'));
  // }, function() {
  //     $(this).attr('src', $(this).attr('src').replace('_on', '_off'));
  // });

  // Colorbox
  // $(".youtube").colorbox({
  //     iframe: true,
  //     innerWidth: 853,
  //     innerHeight: 480
  // });
  //   $('.colorbox').colorbox({
  //   maxWidth: "90%"
  // });
  // フレームの高さをウィンドウサイズに合わせる。
  // var wHeight = $(window).height();
  // $(window).resize(function() {
  //     $(".slideFrame").height($(window).height());
  // }).resize();

  //スクロール追従メニュー
  // var side = $(".sideMenu");
  // var main = $(".content");

  // var window_min = 0;
  // var window_max = 0;

  // var min_move = main.offset().top;
  // var max_move = main.offset().top + main.height() - side.height() - 2 * parseInt(side.css("top"));
  // var threshold_offset = 50;
  // var window_min = min_move - threshold_offset;
  // var window_max = max_move + side.height() + threshold_offset;

  // function window_scroll() {
  //     if ($(window).scrollTop() >= window_min && $(window).scrollTop() < window_max) {
  //         container_move();
  //     }
  // }
  // $(window).bind("scroll", window_scroll);


  // function container_move() {
  //     if ($(window).scrollTop() >= min_move && $(window).scrollTop() <= max_move) {

  //         var margin_top = $(window).scrollTop() - min_move;
  //         side.animate({
  //             "margin-top": margin_top
  //         }, {
  //             duration: 500,
  //             queue: false
  //         });
  //     } else if ($(window).scrollTop() <= min_move) {
  //         side.css("margin-top", 0);
  //     } else if ($(window).scrollTop() > max_move) {
  //         side.css("margin-top", max_move - min_move + "px");
  //     }
  // }
  // container_move();

  // Slide Toggle Menu
  // $('.globalNav_items').hide();
  // $('.js-globalNav').bind('touchstart', function() {
  //   $('.globalNav_items').slideToggle('slow');
  // });

  // Slide Toggle Nav
  // var isTouch = ('ontouchstart' in window) ? 'touchstart' : 'click';
  //   $('.menuBtn').on(isTouch, function() {
  //       $('.gNav_items').slideToggle('slow');
  //       $(this).toggleClass('open');
  //       return false;
  //     });


  // Slide Toggle Index
  // var tglBtn = $('.js-toggleBtn');
  // var tglPanel = $('.js-togglePanel');
  // tglPanel.hide();
  // tglBtn.bind('touchstart', function() {
  //   $(this).next(tglPanel).slideToggle('slow');
  //   $(this).toggleClass('faqBox_title-close');
  // });

  // Fakeloader
  // $("#fakeloader").fakeLoader({
  //       bgColor: "#000",
  //       spinner: "spinner4"
  //   });

  // Animate CSS

  // function animate($ani, $valPos) {
  //     if ($ani === 'fadeInUp' || $ani === 'fadeInLeft' || $ani === 'fadeInRight') {
  //         $(".ani-" + $ani).css("opacity", "0");
  //     }
  //     $(".ani-" + $ani).each(function() {
  //         var imgPos = $(this).offset().top;
  //         var scroll = $(window).scrollTop();
  //         var windowHeight = $(window).height();
  //         if (scroll > imgPos - windowHeight + windowHeight / $valPos) {
  //             $(this).addClass("animated " + $ani);
  //         } else {
  //             $(this).removeClass("animated " + $ani);
  //         }
  //     });
  // }
  // $(window).on('touchstart scroll', function() {
  //     var timer = false;
  //     if (timer !== false) {
  //         clearTimeout(timer);
  //     }
  //     timer = setTimeout(function() {
  //         animate('fadeInUp', '5');
  //         animate('fadeInLeft', '3');
  //         animate('fadeInRight', '3');
  //         animate('bounce', '2');
  //     }, 100);
  // });

});
